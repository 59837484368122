
import UserBulkDiag from './bulk-ops/UserBulkDialogue.vue';
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { useUserList } from '@/composables/useUserList';
import { FetchStates } from '@/helpers/fetch-states';
import { useAuth } from '@/composables/useAuth';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';
import { UserPrefs } from '@/api/dto/user-prefs';
import { exportUsersByQuery } from '@/api/accidentplan-api';
import SearchBar from '../search/SearchBar.vue';
import { UserGroups, UserStatuses } from '@/api/dto/user';

export default defineComponent({
  name: 'UsersList',
  components: {
    UserBulkDiag,
    SearchBar,
  },
  setup() {
    const userList = useUserList();
    const { isVAR, isSuperAdmin } = useAuth();

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    return {
      ...userList,
      FetchStates,
      isVAR,
      isSuperAdmin,
      userPrefs,
      convertDateString,
    };
  },
  data() {
    return {
      search: '',
      selectedUsers: [],
      statusFilter: 'all',
      groupFilter: [],
      bulkDialogueVisible: false,
    };
  },
  methods: {
    selectionChangeHandler(val: any) {
      this.selectedUsers = val;
    },
    async exportUsersAndDownload() {
      const textResp = await exportUsersByQuery(
        this.orgIds,
        this.sortBy ?? 'first_name',
        this.sortDir,
        this.groups,
        this.searchStatus,
        this.searchStr,
      );

      // Credit to https://stackoverflow.com/a/53775165
      // for how to automatically download a response
      const blob = new Blob([textResp], { type: 'text' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'users.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    },
    atSearchChange(val: string) {
      this.searchChangeHandler(val);
    },
    atStatusChange(val: UserStatuses) {
      this.statusFilterHandler(val);
    },
    atUserGroupsChange(val: UserGroups[]) {
      this.groupFilterHandler(val);
    },
  },
});
