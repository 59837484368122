
import UsersList from '@/components/users/UsersList.vue';
import { defineComponent } from 'vue';
import { useOrgManager } from '@/composables/useOrgManager';
import { exportUsersByOrg } from '@/api/accidentplan-api';

export default defineComponent({
  components: {
    UsersList,
  },
  methods: {
    async exportUsersByOrgAndDownload(orgIds: number[]) {
      const textResp = await exportUsersByOrg(orgIds);

      // Credit to https://stackoverflow.com/a/53775165
      // for how to automatically download a response
      const blob = new Blob([textResp], { type: 'text' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'users.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  setup() {
    return {
      ...useOrgManager(),
      exportUsersByOrg,
    };
  },
});
