import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type';
import { computed, reactive, ref, Ref, watch } from 'vue';
import Papa from 'papaparse';
import { getUserImportJobs, importUsers } from '@/api/accidentplan-api';
import { UserImportJob } from '@/api/dto/user-import.dto';

export function useImportForm(formRef: Ref<any>) {
  const selectedFileRef: Ref<File | undefined> = ref(undefined);
  const selectedFile = computed(() => selectedFileRef.value);
  const userCSVFieldsRef: Ref<string[] | undefined> = ref(undefined);
  const userCSVFields = computed(() => userCSVFieldsRef.value);
  const readyToUpload = computed(() => !!selectedFileRef.value);

  const fieldMapForm = reactive({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    user_id: '',
    organizations_id: '',
    phone_number: '',
    var_orgs: '',
    groups: '',
    is_terminated: '',
  });

  const fieldMapRules = {
    id: [
      { required: true, message: 'This field is required!', trigger: 'change' },
    ],
  };

  const uploadState = ref<FetchStates>(FetchStates.UNFETCHED);

  const fileSelected = (file: UploadFile) => {
    selectedFileRef.value = new File([file.raw], 'users.csv', {
      type: 'text/csv',
    });

    fieldMapForm.id = '';
    fieldMapForm.first_name = '';
    fieldMapForm.last_name = '';
    fieldMapForm.email = '';
    fieldMapForm.password = '';
    fieldMapForm.user_id = '';
    fieldMapForm.organizations_id = '';
    fieldMapForm.phone_number = '';
    fieldMapForm.var_orgs = '';
    fieldMapForm.groups = '';
    fieldMapForm.is_terminated = '';
    Papa.parse(selectedFileRef.value, {
      header: true,
      preview: 1,
      skipEmptyLines: true,
      dynamicTyping: false,
      complete: results => {
        userCSVFieldsRef.value = results.meta.fields;
      },
    });
  };

  const fileRemoved = () => {
    selectedFileRef.value = undefined;
  };

  const uploadFile = async () => {
    // validate the form
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    uploadState.value = FetchStates.FETCHING;

    try {
      // Making sure we have an uploaded file
      if (!selectedFileRef.value) {
        throw new Error('Failed to upload (no file selected)');
      }

      // Making our field mapping into a file blob
      const mapFile = new File([JSON.stringify(fieldMapForm)], 'map.json', {
        type: 'application/json',
      });

      // Beginning the file import
      const newJob = await importUsers(selectedFileRef.value, mapFile);

      // Setting the upload state appropriately
      uploadState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);

      alertError(
        error,
        'There was an error in executing the bulk user import.',
      );
      uploadState.value = FetchStates.UNFETCHED;
    }
  };

  return {
    selectedFileRef,
    selectedFile,
    userCSVFieldsRef,
    userCSVFields,
    readyToUpload,
    fieldMapForm,
    fieldMapRules,
    uploadState,
    fileSelected,
    fileRemoved,
    uploadFile,
  };
}

export function useJobTickets() {
  const importJobsRef: Ref<UserImportJob[]> = ref([]);
  const importJobs = computed(() => importJobsRef.value);
  const fetchImportJobsState = ref<FetchStates>(FetchStates.UNFETCHED);

  const fetchImportJobs = async () => {
    fetchImportJobsState.value = FetchStates.FETCHING;

    try {
      const jobFetchResponse = await getUserImportJobs();

      importJobsRef.value = jobFetchResponse.jobs;

      fetchImportJobsState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error in fetching import jobs.');

      fetchImportJobsState.value = FetchStates.UNFETCHED;
    }
  };

  fetchImportJobs();
  const importJobsInterval = setInterval(fetchImportJobs, 5000);

  return {
    importJobsRef,
    importJobs,
    fetchImportJobsState,
    fetchImportJobs,
    importJobsInterval,
  };
}
