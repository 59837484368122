
import { useImportForm } from '@/composables/useImportForm';
import { FetchStates } from '@/helpers/fetch-states';
import { defineComponent } from '@vue/runtime-core';
import { ref } from 'vue';
import { convertDateString } from '@/helpers/date-format';

export default defineComponent({
  name: 'UserImportComp',
  setup() {
    const formRef = ref(null);
    const useForm = useImportForm(formRef);

    return {
      formRef,
      ...useForm,
      convertDateString,
    };
  },
  computed: {
    isUploading() {
      if (this.uploadState === FetchStates.FETCHING) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async submitFile() {
      await this.uploadFile();
      this.$emit('gotoTickets');
    },
  },
});
