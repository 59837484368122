import {
  deleteUsers,
  fetchUserList,
  rehireUsers,
} from '@/api/accidentplan-api';
import { UserGroups, UsersQueryItemDTO, UserStatuses } from '@/api/dto/user';
import { SortDirection } from '@/helpers/data-query';
import { FetchStates } from '@/helpers/fetch-states';
import { OrganizationsModule } from '@/store/organizations';
import { ElMessage } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { APIError } from '@/api';
import { alertError } from '@/helpers/alert-error';

export function useUserList() {
  const users = ref<UsersQueryItemDTO[]>([]);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const returnCount = ref(0);
  const total = ref(0);
  const page = ref(1);
  const pageSize = ref(100);
  const sortDir = ref<SortDirection>(SortDirection.ASC);
  const sortBy = ref<keyof UsersQueryItemDTO>('first_name');
  const groups = ref<UserGroups[]>([]);
  const searchStr = ref<string>('');
  const searchStatus = ref<boolean | undefined>(undefined);
  const orgIds = computed(() => OrganizationsModule.activeOrgIds);

  const fetchUsers = async () => {
    if (orgIds.value.length < 1) {
      users.value = [];
      returnCount.value = 0;
      total.value = 0;
      page.value = 1;

      return;
    }

    fetchState.value = FetchStates.FETCHING;
    try {
      const data = await fetchUserList(
        orgIds.value,
        pageSize.value,
        (page.value - 1) * pageSize.value,
        sortBy.value ?? 'first_name',
        sortDir.value,
        groups.value,
        searchStatus.value,
        searchStr.value,
      );

      users.value = data.results;
      returnCount.value = data.return_count;
      total.value = data.total;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching users.');
      console.error(error);
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const onSortChanged = ({
    order,
    prop,
  }: {
    order: 'ascending' | 'descending';
    prop: keyof UsersQueryItemDTO;
    column: any;
  }) => {
    page.value = 1;
    sortBy.value = prop;

    if (order === 'descending') sortDir.value = SortDirection.DESC;
    else sortDir.value = SortDirection.ASC;
  };

  const groupFilterHandler = (groupFilter: UserGroups[]) => {
    groups.value = groupFilter;
  };

  const statusFilterHandler = (statusRadio: UserStatuses) => {
    switch (statusRadio) {
      case UserStatuses.ALL:
        searchStatus.value = undefined;
        break;
      case UserStatuses.ACTIVE:
        searchStatus.value = false;
        break;
      case UserStatuses.DELETED:
        searchStatus.value = true;
        break;
    }
  };

  const searchChangeHandler = (search: string) => {
    searchStr.value = search;
  };

  const deactivateUser = async (userId: number) => {
    try {
      await deleteUsers([userId]);
    } catch (error) {
      alertError(error, 'There was an error deactivating the user.');
      console.error(error);
    } finally {
      fetchUsers();
    }
  };

  const deactivateUsers = async (users: UsersQueryItemDTO[]) => {
    const userIds = users.map(user => user.id);

    try {
      await deleteUsers(userIds);
    } catch (error) {
      alertError(error, 'There was an error deactivating users.');
      console.error(error);
    } finally {
      fetchUsers();
    }
  };

  const reactivateUser = async (userId: number) => {
    try {
      await rehireUsers([userId]);
    } catch (error) {
      alertError(error, 'There was an error reactivating users.');
      console.error(error);
    } finally {
      fetchUsers();
    }
  };

  const reactivateUsers = async (users: UsersQueryItemDTO[]) => {
    const userIds = users.map(user => user.id);

    try {
      await rehireUsers(userIds);
    } catch (error) {
      alertError(error, 'There was an error reactivating users.');
      console.error(error);
    } finally {
      fetchUsers();
    }
  };

  watch(page, fetchUsers);
  watch(pageSize, fetchUsers);
  watch(sortBy, fetchUsers);
  watch(sortDir, fetchUsers);
  watch(orgIds, fetchUsers);
  watch(groups, fetchUsers);
  watch(searchStr, fetchUsers);
  watch(searchStatus, fetchUsers);

  fetchUsers();

  return {
    users,
    fetchState,
    returnCount,
    total,
    page,
    pageSize,
    sortDir,
    sortBy,
    groups,
    orgIds,
    searchStr,
    searchStatus,
    fetchUsers,
    onSortChanged,
    groupFilterHandler,
    statusFilterHandler,
    searchChangeHandler,
    deactivateUser,
    deactivateUsers,
    reactivateUser,
    reactivateUsers,
  };
}
