
import { defineComponent } from '@vue/runtime-core';
import UserImportComp from './UserImport.vue';
import UserJobTicketsComp from './UserJobTickets.vue';

export default defineComponent({
  name: 'UserBulkDialogue',
  props: ['isVisible'],
  components: {
    UserImportComp,
    UserJobTicketsComp,
  },
  emits: ['dialogClosing'],
  data() {
    return {
      version: 0,
      activeTab: 'update',
      jobRefreshFn: () => {
        return;
      },
    };
  },
  methods: {
    dialogClosing() {
      this.version++;
      this.$emit('dialogClosing');
    },
    gotoTickets() {
      this.activeTab = 'tickets';
      this.jobRefreshFn();
    },
    setJobRefreshFn(func: () => void) {
      this.jobRefreshFn = func;
    },
  },
  computed: {
    dialogueVisible: function() {
      if (this.isVisible === true) {
        return true;
      } else {
        return false;
      }
    },
  },
});
