
import { UserPrefs } from '@/api/dto/user-prefs';
import { useJobTickets } from '@/composables/useImportForm';
import { UserPrefsModule } from '@/store/userprefs';
import { defineComponent, onUnmounted } from '@vue/runtime-core';
import { onMounted, Ref, ref } from 'vue';
import { convertDateString } from '@/helpers/date-format';

export default defineComponent({
  name: 'UserJobTicketsComp',
  setup() {
    const useForm = useJobTickets();

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    onUnmounted(() => {
      clearInterval(useForm.importJobsInterval);
    });

    return {
      userPrefs,
      ...useForm,
      convertDateString,
    };
  },
  methods: {
    refreshJobs() {
      this.fetchImportJobs();
    },
  },
  created() {
    this.$emit('refreshFn', this.refreshJobs);
  },
});
