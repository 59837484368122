import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserImportComp = _resolveComponent("UserImportComp")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_UserJobTicketsComp = _resolveComponent("UserJobTicketsComp")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "Bulk User Operations",
    modelValue: _ctx.dialogueVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogueVisible = $event)),
    onClose: _ctx.dialogClosing,
    width: "80%",
    class: "user-bulk-diag"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "Bulk Update",
            name: "update"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UserImportComp, {
                onClose: _ctx.dialogClosing,
                onGotoTickets: _ctx.gotoTickets,
                key: _ctx.version
              }, null, 8, ["onClose", "onGotoTickets"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: "Job Tickets",
            name: "tickets"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UserJobTicketsComp, {
                onClose: _ctx.dialogClosing,
                onRefreshFn: _ctx.setJobRefreshFn,
                key: _ctx.version
              }, null, 8, ["onClose", "onRefreshFn"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}